import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrl: './dashboard-menu.component.scss',
})
export class DashboardMenuComponent implements OnInit {
  public activeIndex: number;
  public activeSubmenuIndex: number;
  menuOpen: boolean = true;
  public open = new Map<number, boolean>([
    [2, false],
    [4, false],
    [6, false],
  ]);
  public isOpen = false;

  public selectedNgo: any = null;
  public sessionUser: any;
  public userNgoList: any = [];

  constructor(
    private ngoService: NgoService,
    private readonly router: Router,
    private readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService,
    private readonly commonSharedService: CommonSharedServiceService
  ) {}

  public ngOnInit(value?: any): void {
    const user = this.localStorageService.getSessionUser();

    const aclMenu = value || this.getACLFromUrl();
    this.activeIndex = aclMenu?.menu_index;

    const selectedNgo = this.localStorageService.getNgoInLocalStorage();
    if (selectedNgo) {
      this.selectedNgo = selectedNgo;
    }
    this.getNgoByUserId();
  }

  public getNgoByUserId(): void {
    this.sessionUser = this.localStorageService.getSessionUser();
    this.ngoService
      .getNgoByUserId(this.sessionUser?.id)
      .subscribe((result: any) => {
        this.userNgoList = result;
      });
  }

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  public selectOrganization(ngo: any) {
    this.isOpen = false;
    if (!(JSON.stringify(this.selectedNgo) === JSON.stringify(ngo))) {
      this.selectedNgo = ngo;
      this.localStorageService.setNgoInLocalStorage(ngo);
      this.localStorageService.deleteProjectFromLocalStorage();
      this.triggerNgoSelection();
    }
  }

  private triggerNgoSelection() {
    this.commonSharedService.ngoSelected();
  }

  public toggleMenu(index: number): void {
    this.activeIndex = index;
    if (this.open.has(index)) {
      this.toggleOpen(index);
    }

    if (index === 4 && this.userNgoList?.length === 0) {
      this.router.navigate(['/dashboard/manage-my-ngo']);
    } else if (index === 4 && this.userNgoList?.length > 0) {
      if (!this.localStorageService.getNgoInLocalStorage()) {
        this.utilitiesService.showSwal('warning', 'Select an NGO first.');
      }
    }
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }
  public isSubmenuActive(index: number): boolean {
    return this.activeSubmenuIndex === index;
  }
  public setActiveIndex(index: number): void {
    this.activeSubmenuIndex = index;
  }

  private toggleOpen(index: number): void {
    const value = this.open.get(index);
    this.open.forEach((value, key) => {
      this.open.set(key, false);
    });
    this.open.set(index, !value);
  }

  public openCloseMenu(): void {
    // this.menuOpen = !this.menuOpen;
  }

  private getACLFromUrl(): any {
    const value = this.router.url;
    if (value.includes('manage-my-ngo')) {
      return { menu_name: 'Manage My NGO', menu_index: 4 };
    } else if (value.includes('claim-your-ngo')) {
      return { menu_name: 'Manage My NGO', menu_index: 4 };
    } else if (value.includes('register-new-ngo')) {
      return { menu_name: 'Manage My NGO', menu_index: 4 };
    } else if (value.includes('bookmarked-ngos')) {
      return { menu_name: '', menu_index: null };
    } else if (value === '/dashboard') {
      return { menu_name: 'Home', menu_index: 1 };
    }
  }
}
