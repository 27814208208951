import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonSharedServiceService {
  private showSearchModalValue = new BehaviorSubject<boolean>(false); // Default value
  currentSearchModalValue = this.showSearchModalValue.asObservable();

  constructor() {}

  changeSearchModalValue(value: boolean) {
    this.showSearchModalValue.next(value);
  }

  private ngoSelectionActionSource = new Subject<void>();
  ngoSelectionAction$ = this.ngoSelectionActionSource.asObservable();
  public ngoSelected() {
    this.ngoSelectionActionSource.next();
  }
}
